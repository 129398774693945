@import "../../../sass/variables";
  
  // Alternate styles
  //
  // Generate contextual modifier classes for colorizing the alert.
  
  .alert-success {
    background-color: $alert-success-bg;
    border-color: $alert-success-border;
    color: $alert-success-text;
    border-left: 5px solid $alert-success-text;
  }
  .alert-info {
    background-color: $alert-info-bg;
    border-color: $alert-info-border;
    color: $alert-info-text;
    border-left: 5px solid $alert-info-text;
  }
  .alert-warning {
    background-color: $alert-warning-bg;
    border-color: $alert-warning-border;
    color: $alert-warning-text;
    border-left: 5px solid $alert-warning-text;
  }
  .alert-danger {
    background-color: $alert-danger-bg;
    border-color: $alert-danger-border;
    color: $alert-danger-text;
    border-left: 5px solid $alert-danger-text;
  }