.copy-btn {
    &.btn-primary {
      font-size: 13px;
      padding: 3px 15px !important;
      border-radius: 30px !important;
      cursor: pointer;
      background-color: #fff !important;
      color:  var(--atm-color-blue-60) !important;
      border-color:  var(--atm-color-blue-60) !important;
      &:hover {
        background-color: var(--atm-color-blue-70) !important;
        border-color:  var(--atm-color-blue-70)!important;
        color: #fff !important;
      }
    }
  }   