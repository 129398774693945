.pubhub-doc-container {
  overflow: auto;
  overflow-x: hidden;
  background: #fff;
  width: calc(100% - 332px);
  margin-left: 331px;
  min-height: 90vh;
  &.full {
    width: 100%;
    left: 0;
    margin-left: 0;
  }

  &.navigate {
    border-left: solid 1px #dfdfdf;

    // &:before {
    //   content: " ";
    //   width: 1px;
    //   display: inline-block;
    //   height: 100%;
    //   border-left: solid 1px #dfdfdf;
    //   overflow: hidden;
    //   position: absolute;
    //   margin-left: -1px;
    // }
  }

}

section.doc-content {
  //padding: 0 40px 40px 0px;
  font-weight: 300;
  line-height: 1.8em;
  //border-bottom: solid 1px $light-gray-3;
  word-wrap: break-word;

  /* lists */
  ul {
    margin: 10px 0px 10px 20px;

    li {
      margin-left: 20px;

      a {
        text-decoration: none;
      }
    }

  }

  ol {
    margin: 10px 0px 10px 20px;
    & > li {
      margin-left: 20px;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  ol, ul {
    ol {
      list-style: lower-roman;
    }

    ul {
      list-style: circle;
    }

    ol, ul {
      ol {
        list-style: lower-alpha;
      }

      ul {
        list-style: square;
      }
    }
  }

  /* table */
  table {
    display: block;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    width: max-content;
    max-width: 100%;
    overflow: auto;
    word-break: normal;
    table-layout: initial;

    caption {
      text-align: left;
      margin: 30px 0px 10px 0px;
      font-weight: 400;
      color: #999;
    }

    thead {
      border-bottom: solid 1px #ccc;
    }

    th {
      padding: 15px;
      padding-bottom: 20px;
      color: $cisco-blue;
      font-weight: 500;
    }

    td {
      padding: 15px;
      padding-bottom: 20px;
      vertical-align: top;
    }
    tr {
      //border-bottom: solid 1px #ccc;
      &:nth-child(2n) {
        background-color: #f8f8f8;
      }
    }

    tr > td:first-child {
      //white-space: nowrap;
    }

  }

  /* code */
  code {
    padding: 0;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 3px;
    &:before, &:after {
      letter-spacing: -0.2em;
    }
  }

  pre > code {
    padding: 0;
    margin: 0;
    font-size: 100%;
    word-break: normal;
    white-space: pre;
    background: transparent;
    border: 0;
  }

  pre code {
    display: inline;
    max-width: initial;
    padding: 0;
    margin: 0;
    overflow: initial;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
    &:before, &after {
      content: normal;
    }
  }

  aside {
    padding: 1em;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    background: #a7dbfa;
    line-height: 1.6;
  }

  /* blockquote */
  blockquote {
    padding: 10.5px 21px;
    margin-left: 0 !important;
    border-left: 4px solid $status-blue;
    background: $pale-gray-2;
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 40px;
    -webkit-margin-end: 40px;
    p {
      font-size: 14px;
      margin: 0;
      font-style: italic;
    }
    &:first-child, &:last-child {
      margin-top: 0px
    }

    &[type=xmart] {
      &:before {
        display: none;
      }
      img {
        margin-right: 6px;
      }
    }

    &.note.success{
      border-left: 4px solid #3c763d;
      background: #dff0d8;
    }

    &.note.warning{
      border-left: 4px solid #8a6d3b;
      background: #fcf8e3;
      color: #8a6d3b;
    }

    &.note.error{
      border-left: 4px solid #a94442;
      background: #f2dede;
      color: #a94442;
    }

    &.note.danger{
      border-left: 4px solid #a94442;
      background: #f2dede;
      color: #a94442;
    }





  }

  /* Other */
  img {
    border-style: none;
    max-width: 100%;
  }

  b, strong {
    font-weight: 500;
  }

  a {
    background-color: transparent;
    color: $cisco-blue;
    text-decoration: none;
    -webkit-text-decoration-skip: objects;
    &:active, &:hover {
      outline-width: 0;
    }
    &:focus {
      outline: thin dotted;
    }
  }

  p {
    margin: 16px 0px;
  }

  hr {
    color: #919699;
    width: 100%;
    margin: 16px 0;
    noshade: "noshade"
  }

  .anchor {
    display: inline-block;
    padding-right: 2px;
    margin-left: -18px;
    .anchor:focus {
      outline: none;
    }
  }

  .highlight {
    margin-bottom: 16px;
  }

  dl {
    padding: 0;
    dt {
      padding: 0;
      margin-top: 16px;
      font-size: 1em;
      font-style: italic;
      font-weight: bold;
    }
    dd {
      padding: 0 16px;
      margin-bottom: 16px;
    }
  }

  abbr {
    border-bottom: none;
  }

  em {
    font-style: italic;
  }
  dfn {
    font-weight: bold;
  }

  h1, h2, h3 {
    clear: both;
  }

  .table-inverse tr {
    color: #fff;
    background-color: #292b2c !important;
  }
}

/*.loadingSpinner {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  margin-left: 70px;
  margin-top: -50px;
  z-index: 20;
}*/

section.doc-content.html {
  padding: 0;
}

section.doc-content {
  .pagination-custom {
    ul {
      li {
        margin: 10px 5px 10px 0px;
      }
    }
  }
}


.overlay {
  background: #e9e9e9;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  .loading-img {
    background: url('img/balls.svg') center center no-repeat;
    height: 100%;
    z-index: 1001;
  }
}

div.pageNavigationLinks_bottom {
  margin: 10px 40px 40px 10px;
  float: right;
  & a {
    min-width: 100px;
    min-height: 30px;
    background-color: $cisco-blue;
    color: $white;
    margin: 10px 10px;
    border: none;
    border-radius: 5px;
    outline: none;
    transition: background-color 0.2s ease-in;
    padding: 3px 12px;
    /* &:disabled{
       background-color: $light-gray-2;
       cursor: not-allowed;
     }*/
    &:hover:enabled {
      background-color: $light-blue;
    }
    &.nextLink:after {
      content: "\003E";
      padding: 5px;
    }
    &.prevLink:before {
      content: "\003C";
      padding: 5px;
    }
  }

}

$one-col-code-width: 20%;
$two-col-code-width: 50%;
$max-width: 1140px;

section.doc-content {
  .orphen-link-goback-section {
    padding-bottom: 0 !important;
    margin-bottom: -20px;
    .orphen-link-goback-link {
      cursor: pointer;
      color: #049fd9;
    }
  }
  & .column-doc.column-doc-static {
    position: relative;
    top: 0;
    overflow: hidden;
    & > h1, & > h2, & > h3, & > h4,
    & > h5, & > h6, & > p, & > div,
    & > table, & > ul, & > ol, & > aside,
    & > dl, & > blockquote, & > .code-tab-container {
      //margin-right: $one-col-code-width;
      max-width: $max-width;
      clear: both;
      padding: 30px;
    }
    & > div.column-code {
      position: absolute;
      //background-color: white;
      width: calc(100% - 1140px);
      min-width: $one-col-code-width;
      max-width: none;
      height: 100%;
      margin-right: 0;
      top: 0;
      right: 0;
      //z-index: -1;
    }
  }
  & .column-doc.two-columns {

    & > div.column-code {
      width: calc(100% - 1140px);
      min-width: 50%;
      max-width: none;
      border-left: 1px solid #e7e7e7;
      border-right: 1px solid #e7e7e7;
      margin-right: 0;
      background: #383838;

    }

    .code-tab-title-container {
      border-bottom-color: #000;
    }
    .code-tab-title-container .code-tab-title {
      color: #fff;
    }

    & > h1, & > h2, & > h3, & > h4,
    & > h5, & > h6, & > p, & > div,
    & > table, & > ul, & > ol, & > aside,
    & > dl, & > blockquote {
      margin-right: 50%;
      padding: 30px;
    }

    & .code-tab-container {
      //margin-right: calc(-66.66% - 2px);
      //width: 5000px;
      //margin-top: -90px;

      & .code-tabs {
        position: relative;
        margin: 0px;
        //width: calc(100% - 1140px);
        min-width: 0;
        max-width: none;

        &:before {
          display: block;
        }

      }
    }

  }
}

& .full section.doc-content .column-doc {
  & > h1, & > h2, & > h3, & > h4,
  & > h5, & > h6, & > p, & > div,
  & > table, & > ul, & > ol, & > aside,
  & > dl, & > blockquote, & > .code-tab-container {
    max-width: none;
  }
}

&[data-platform="sm"] {

  height: auto !important;
  overflow: visible;

  .pubhub-doc-container.navigate {
    margin-left: 0px;
    width: 100%;
    // padding-top: 57px;
    border: 0 none;
    overflow-x: auto;
  }

  .column-code {
    display: none;
  }
  .widgets {
    margin-right: 0 !important;
  }

  section.doc-content {
    //padding: 0 24px 24px 24px;

    .column-doc {
      overflow-x: auto;
    }

    img {
      height: auto;
    }

  }

}

.column-doc.swagger{
  .pb-doc-content{
    padding-top: 30px;
  }
}

[data-selector='a[data-type=pubhub-file]']{
  display: inline-block;
}
