.breadCrumb-container {
    .lastItem {
        cursor: default;
        font-weight: normal;
        font-size: 14px;
    }
    .bcItems {
        cursor: pointer;
        font-weight: normal;
        font-size: 14px;
        color: #0076D5;
    }
    .splitter {
       padding-left: 7px;
       padding-right: 7px;
       font-size: 12px;
    }
}