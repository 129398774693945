.footer-container {

    .getting_started_container {
        // min-height: 500px;
        width: 100%;
        background-color: var(--atm-color-brand-midnightblue);
        color: #FFFF; 
    }

    .getting_started_container,.support_container {
       // height: auto!important;
        .header_text {
            color: var(--atm-link-text-default);
            font-size: var(--atm-font-size-200) !important;
            font-weight: 500 !important;
            margin-bottom: 20px !important;
        }
        .header_sub_text {
            color: var(--atm-link-text-default);
            font-size: var(--atm-font-size-250) !important;
            font-weight: 300 !important;
            margin-bottom: 20px !important;
        }
        .sub_text {
            color: var(--atm-color-grayscale-100);
            font-size: var(--atm-font-size-100) !important;
            font-weight: 500 !important;
            margin-bottom: 25px !important;
        }
        .mb-0 {
            margin-bottom: 0!important;
        }
    }
    
    .support_container {
        width: 100%;
        text-align: center;
        background-image: url(../../../../sass/img/Rectangle_19.png) ;
      //  background-image: url("https://local.cisco.com:3002/pages/site/page/assets/Rectangle 19.png");
        color: #FFFF;
        background-repeat: no-repeat;
        background-size: cover;
        .header_text {
            color: var(--atm-color-grayscale-0);
        }
        .sub_text {
            color: var(--atm-color-grayscale-0);
            font-weight: 200 !important;
        }
    }


    .wt-700 {
        width: 700px;
    }
    .hyperlink:after {
        background: url('../../../../sass/doc/img/new_page.svg') center center no-repeat;
        width: 26px;
        height: 24px;
        display: inline-block;
        content: " ";
        position: absolute;
        right: 6px;
    }
    
    .support_link_footer {
        color: var(--atm-color-brand-midnightblue);
        .header_text {
            font-size: 14px !important;
            margin-bottom: 15px !important;
            font-weight: 700 !important;
        }
        .sub_text{
            font-size: 14px !important;
            font-weight: 300 !important;
            color: var(--atm-color-grayscale-70) !important;
            .footer-link {
                text-decoration: none !important;
                color: var(--atm-color-grayscale-70) !important;
            }
        }
        .sub_text {
            margin-bottom: 15px !important;
        }
        .logo-text {
            font-size: 35px !important;
            font-weight: 700 !important;
            line-height: 34px !important;
            text-align: end;
        }
        .copy-right-text{ 
            font-size: var(--atm-font-size-85);
            font-weight: 400 !important;
            color: var(--atm-color-grayscale-100) !important;
        }
    }

    .getting-started-image {
     //   background: url(../../../../sass/img/Getting_Started.png);
       // background: url(https://it-developer-nprd-dev.cisco.com/sdk/5b08f319439bfd8b6085.png);
        background-repeat: no-repeat;
        width: 380px;
        height: 340px;
        background-size: contain;
    }
    .section-up {
        width: 59%;
        background: rgba(255, 255, 255, .73);
        padding: 30px;
    }
}

