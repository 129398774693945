.section-header-container {
  z-index: 99;
  .info-outline {
    color: var(--atm-color-grayscale-50);
    font-size: 17px;
  }

  hbr-dropdown::part(panel) {
    overflow: hidden;
  }

  .info-circle {
    color: var(--atm-color-grayscale-30);
  }
  
  .spliter {
    color: var(--atm-color-grayscale-20);
  }

  .title {
    color: var(--atm-color-grayscale-50);
  }
}
