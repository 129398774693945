.global-search-container {
    display: flex;
    height: 45px;
    position: relative;
    padding: 0;
    transition: all .3s ease;
    &.small-screen {
        display: flex!important;
        position: absolute;
        right: 2px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 999;
        .search-container {
            width: 100%!important;
            max-width: 350px!important;
        }
    }
    .search-container {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 0px 18px;
        min-width: 270px;
        width: 30vw;
        height: 100%;
        background: var(--atm-color-grayscale-0);
        border: 1px solid var(--atm-color-content-weak-default);
        border-radius: 28px;
        margin-bottom: 5px!important;
        i {
            font-size: 18px!important;
        }
        hbr-progress-ring {
            stroke-width: 2;
            position: absolute;
            right: 0;
        }
        input {
            flex: 1;
            border: none;
            outline: none;
            height: 100%;
            padding: 0 10px;
            font-size: 14px;
            border-radius: 28px;
        }
    }
    .no-record {
        margin-top: 10px;
        padding: 0 15px;
        i {
            color: var(--atm-color-red-60);
            margin-right: 5px;
        }
    }
    .result-container {
        position: absolute;
        width: 100%;
        min-width: 370px;
        background: var(--atm-color-grayscale-0);
        z-index: 9;
        padding: 15px 0;
        border: 1px solid var(--atm-color-grayscale-20);
        border-radius: 5px;
        right: 0;
        .tag-container {
            padding: 0 15px 15px 15px;
            border-bottom: 1px solid var(--atm-color-grayscale-10);
            .filters {
                color: var(--atm-color-grayscale-70);
                font-weight: 16px;
                font-weight: 700;
                margin-bottom: 5px;
                &:not(:first-child) {
                    margin-top: 10px;
                }
            }
            hbr-tag {
                &:not(:last-child) {
                    margin-right: 5px;
                }
            }
        }
        .result-content-container {
            max-height: 450px;
            overflow-y: scroll;
            overflow-x: auto;
            overflow-wrap: break-word;
            &::-webkit-scrollbar {
                width: 12px;
            }
            &::-webkit-scrollbar-track {
                background: var(--atm-color-grayscale-10);
            }
            &::-webkit-scrollbar-thumb {
                background: var(--atm-color-grayscale-30);
            } 
            &::-webkit-scrollbar-thumb:hover {
                background: var(--atm-color-grayscale-50);
            }
            .group-type {
                padding: 10px 15px;
                font-size: 16px;
                font-weight: 700;
                background-color: var(--atm-color-blue-5);
            }
            .result {
                display: block;
                padding: 15px 15px 15px 30px;
                text-decoration: none;
                color: var(--atm-color-grayscale-70);
                cursor: pointer;
                &:not(:last-child) {
                    border-bottom: 1px solid var(--atm-color-grayscale-10);
                }
                .result-title {
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 10px;
                }
                .result-content {
                    font-size: 14px;
                    font-weight: 300;
                }
                &:hover {
                    background-color: var(--atm-color-blue-5);
                }
            }
        }
    }
}