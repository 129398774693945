@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import "../../../../sass/variables";

.checkbox-container {
    display: flex;
    flex-wrap: wrap;

    .checkbox-item {
        width: 50%;
        box-sizing: border-box;
        padding: 5px;
    }
}

.search-container {
    position: relative;
    width: 400px;
    margin-bottom: 15px;
    .search-input {
        width: 100%;
        padding: 8px 5px 8px 15px !important;
        border: 1px solid #F0F0F0 !important;
        border-radius: 18px !important;
    }
    .search-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px !important;
        color: #0D274D;
        pointer-events: cursor;
    }
}

Button {
    cursor: pointer;
}

.modal-dialog {
    min-width: 400px!important;
}

.spinner-container {
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.associated-table {
    vertical-align: middle !important;
}

.myappsTable {
    width: 100% !important;
    max-width: 100%!important;

    hbr-tab-panel {
        padding: 20px 0;
    
        .gateway-container {
            height: 400px;
        }
        .gateway-wrapper {
            margin-right: 30px;
    
            .box {
                background-color: white;
                border: 2px solid var(--atm-color-grayscale-5);
                border-radius: 8px;
                width: 300px;
                padding: 50px;
                text-align: center;        
                transition: transform 0.3s ease, box-shadow 0.3s ease;
    
                &:hover {
                    transform: translateY(-6px);
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
                }
                img {
                    width: 100%;
                    height: auto;
                    border-radius: 4px;
                    background-position: -2px -95px;
                    background-size: 406px;
                }
            
                .title {
                    margin-top: 10px;
                    font-size: 24px;
                    font-weight: 300;
                    color: var(--atm-color-grayscale-100);
                }
            }
        }
      }
}


.hidetext {
    -webkit-text-security: disc;
}

.header {
    font-weight: 500 !important;
    margin-bottom: 0 !important;

    ~span {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        font-size: 14px !important;
    }
}

.api-status {
    span {
        min-width: 95px;
        border: 1px solid;
        border-radius: 500px;
        padding: 0px 10px;
        text-transform: capitalize;
        display: inline-block;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
    }

    .pending {
        color: $brand-warning !important;
    }

    .approved {
        color: $brand-success !important;
    }

    .denied {
        color: $brand-danger !important;
    }
}

.api-version {
    padding: 2px 4px;
    background-color: #0076a6;
    color: $white;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
}

.cursor {
    cursor: pointer;
}

.pl-5 {
    padding-left: 5px;
}

.pl-10 {
    padding-left: 10px;
}

.green {
    color: $green !important;
}

.pubhub-page-title {
    font-size: 32px;
    font-weight: 400!important;
    color: $blue!important;
}

.api-version {
    background-color: var(--atm-color-blue-60);
    color: var(--atm-color-grayscale-0)!important;
    line-height: 1;
    padding: 5px 10px;
    font-weight: 400;
}

.pubhub-table {
    display: table!important;
    table-layout:fixed !important;
    width: 100%!important;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    thead {
        border: 0!important;
        tr {
            background-color: var(--atm-color-grayscale-5)!important;
            border: 0!important;
            th {
                padding: 10px 15px!important;
                &:nth-child(1) {
                    width: 5%;
                }
    
                &:nth-child(2) {
                    width: 25%;
                }
    
                &:nth-child(3) {
                    width: 20%;
                }

                &:nth-child(4) {
                    width: 20%;
                }
    
                &:nth-child(5) {
                    width: 15%;
                }
    
                &:nth-child(6) {
                    width: 15%;
                }
            }
        }
    }
    tbody {
        tr {
            //table-layout: fixed !important;
            overflow: scroll !important;
            border-bottom: 2px solid var(--atm-color-grayscale-10)!important;
            .ln-ht-13 {
                line-height: 13px !important;
            }

            .ln-ht-19 {
                line-height: 19px !important;
            }

            td {
                vertical-align: middle !important;
                font-size: 14px !important;
                font-weight: 200 !important;
                background-color: var(--atm-color-grayscale-0) !important;
                padding: 15px!important;

                &:nth-child(1) {
                    width: 5%;
                    i {
                        font-size: 12px!important;
                        color: var(--atm-color-blue-60);
                        font-weight: 700;
                    }
                }
    
                &:nth-child(2) {
                    width: 25%;
                }
    
                &:nth-child(3) {
                    width: 20%;
                }
                &:nth-child(4) {
                     width: 20%;
                     text-overflow: ellipsis;
                     white-space: nowrap;
                     overflow: hidden;

                }
    
                &:nth-child(4) {
                    width: 15%;
                }
    
                &:nth-child(5) {
                    width: 15%;
                }
            }
            .hide-content {
                -webkit-text-security: disc;
                display: inline-block;
                width: 50%;
                overflow: hidden;
                white-space: nowrap;
                line-height: 1;
                vertical-align: middle;
            }
            .elipsis {
                display: inline-block;
                width: 50%!important;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                vertical-align: middle;
            }
            
            .no-records {
                text-align: center;
                width: 100%;
                background-color: var(--atm-color-grayscale-0);
                font-size: 14px !important;
                font-weight: 200 !important;
                padding: 15px;
            }

            .dropdown-menu {
                display: block !important;
                position: absolute !important;
                left: -150px !important;
                top: 100%;
                transform: translateY(10px);
                z-index: 1000;
                cursor: pointer;
            }
            .expanded-row {
                padding: 5px 0!important;
                font-size: 14px !important;
                font-weight: 300 !important;
                .expanded-row-container {
                    background-color: var(--atm-color-grayscale-5) !important;
                    padding: 24px !important;
                    .col-details {
                        .expanded-row-action {
                            display: inline-block;
                            >:nth-child(2) {
                                display: inline-block;
                            }
                            .eye, .eye-closed {
                                font-size: 18px !important;
                                vertical-align: middle!important;
                            }
                        }
                        .semi-bold-black {
                            font-weight: 400;
                            color: var(--atm-color-grayscale-100);
                        }
                        .row-expend-link {
                            color: var(--atm-color-blue-60)!important;
                            font-weight: 400;
                            cursor: pointer;
                            padding: 5px;
                            i {
                                vertical-align: middle !important;
                                font-size: 14px !important;
                            }
                        }
                        .hide-content {
                            -webkit-text-security: disc;
                            display: inline-block;
                            width: 45%;
                            overflow: hidden;
                            white-space: nowrap;
                            line-height: 1;
                            vertical-align: middle;
                        }
                    }
                    .elipsis {
                        display: inline-block;
                        width: 45%!important;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        vertical-align: middle;
                    }
                    .expanded-row-label {
                        font-weight: 700;
                        margin-right: 5px;
                        white-space: nowrap;
                    }
                    .expanded-row-content {
                        margin-right: 10px;
                        &.client-id {
                            display: inline-block;
                            width: 45%;
                            overflow: hidden;
                            white-space: nowrap;
                            line-height: 1;
                            vertical-align: middle;
                            text-overflow: ellipsis;
                        }
                    }
                    .top-row {
                        display: flex;
                        flex-direction: row;
                        padding-bottom: 24px;
                        border-bottom: 1px solid var(--atm-color-grayscale-20);
                        margin: 0!important;
                    }
                    .bottom-row {
                        padding-top: 24px;
                        margin: 0!important;
                        .api-section {
                            display: flex;
                            flex-direction: column;
                            border-right: 1px solid var(--atm-color-grayscale-20);
                            &:last-child {
                                border-right: 0;
                            }
                            .expanded-row-label {
                                line-height: 1;
                            }
                            .api-list-container {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;

                                span {
                                    padding: 5px;
                                    color: var(--atm-color-grayscale-100);
                                    font-weight: 400;
                                }
                                >:first-child {
                                    width: 45%;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    padding-left: 0;
                                }
                            }
                            
                            .toggle-button {
                                color: var(--atm-color-blue-60) !important;
                                cursor: pointer;
                                font-weight: 500;
                                margin-top: 15px;
                                display: inline-block;
                            }
                        }
                    }
                    
                }
            }
            .action-icons {
                color: var(--atm-color-blue-60);
                padding: 5px!important;
                font-size: 14px!important;
            }
        }
    }
}

.app-modal-popup {
    .modal-dialog {
        .modal-content {
            .modal-header {
                .modal-title {
                    font-size: 20px!important;
                    font-weight: 700!important;
                }
            }
        }
    }
    .modal-body {
        .api-name {
            display: inline-block;
            max-width: 200px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            vertical-align: middle;
            margin-right: 10px;
        }
        table thead tr th {
            padding: 0.8rem 0.5rem!important;
        }
        hbr-input, hbr-textarea, hbr-select {
            margin-bottom: 16px;
        }
        hbr-input::part(label), hbr-textarea::part(label), hbr-select::part(anchor__label) {
            color: var(--atm-color-grayscale-60);
            font-weight: 700;
        }        
        hbr-textarea::part(help-text) {
            text-align: right;
        }
        hbr-textarea:not([error=false])[error]::part(help-text) {
            text-align: left;
        }
        hbr-textarea::part(textarea) {
            resize: none;
        }
        .dropdown-menu {
            width: 100%;
            padding: 12px;
        }
        .no-resize {
            resize: none;
        }
    }
    &.custom-width-650 {
        .modal-dialog {
            max-width: 650px!important;
         }
    }
    &.custom-width-540 {
        .modal-dialog {
            max-width: 540px!important;
         }
    }
}

.gateway-wrapper {
    .title {
        font-size: 18px !important;
    }
.gateway-img {
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    &.mulesoft {
    width: 60px;
    background-image: url('../../../../sass/img/mulesoft-logo.svg');
    }
    &.cisco-api-console {
    width: 100px;
    background-image: url(https://www.cisco.com/c/dam/cdc/ui/atm/3-0-0/images/illustrations/supporting-graphics/cisco/cisco-with-padding.svg);
    }
}
}