.header_data {
    color: white;
    font-weight: 500;
    font-size: 18px;
    background-color: var(--atm-breadcrumb-separator-default);
    padding: 7px;
}

.section-data {
    border-bottom: 1px solid var(--atm-color-grayscale-20);
}