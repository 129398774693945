.title-text {
   font-size: 21px;
   font-weight: 500;
   
}

@for $i from 1 through 10 {
    .height-#{$i * 10} {
      min-height: #{$i * 10}vh;
    
    }
}

.fullPage {
    justify-content: center;
    align-items: center;
    gap: 100px;
    .content-div {
        text-align: left;
    }
    .title-text {
        font-size: 30px;

    }
}
  