.portalCapabilities {
    .card {
        border-radius: 15px;
        min-width: 250px;
        background: linear-gradient(180deg, rgba(242, 242, 242, 0.9) 20%, rgba(253, 242, 212, 0.9) 84%);
        backdrop-filter: blur(2px);

        i {
            font-size: 55px!important;
            height: 65px;
        }
        h5 {
            margin-bottom: 16px !important;
        }
    }
    .feature-description {
        border-top: 1px solid var(--atm-color-grayscale-30);
        padding: 16px 0;
        
    }

    .sub-text {
        color: white;
        width: 70%;
    }

    .main-section-header {
        color: white !important;
    }
    .feature-description {
        font-size: 16px;
    }
}