.common-modal {

.head-txt {
    font-size: 14px;
    width: 110px;

}
.pass-txt {
    font-size: 20px;
    width: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.sub-txt {
    width: 260px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.eye-icon {
    font-size: 20px !important;
    margin-right: 10px !important;
}
.secureText {
    letter-spacing: -2px;
    line-height: 19px;
    font-size: 28px;
}
}
.modal__panel {
    padding-left: 20px !important;
    border-radius: 10px;
}
.modal-title {
    color: var(--atm-color-blue-60) !important;
    font-size: large;
  }
.denial_label {
    color: var(--atm-color-grayscale-70);
    margin-bottom: 5px;
}
.help-text {
    text-align: right !important;    
}