$pagination-button-color: #6B7280;
$pagination-button-bg-color: #E2E2E2;

.pagination-custom {
    justify-content: flex-end !important;
    color: $pagination-button-color;
    font-size: 14px;
    .form-select {
        padding: 0 30px 0 10px;
    }
}

.pagination-custom ul {
    list-style: none !important;
}

#pubhub-widgets .page-link, .pubhub-widgets .page-link {
    color: $pagination-button-color;
}

#pubhub-widgets .page-item.active .page-link, .pubhub-widgets .page-item.active .page-link {
    color: $pagination-button-color;
    border: none;
}


.page-item {
    margin-right: 5px;
}

.page-link {
    border: none !important;
}

#pubhub-widgets .page-item.active .page-link, .pubhub-widgets .page-item.active .page-link {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: $pagination-button-bg-color;
    border: none;
}

.item-link {
    width: 35px;
    height: 35px;
}

#pubhub-widgets .page-link:focus, #pubhub-widgets .page-link:hover, .pubhub-widgets .page-link:focus, .pubhub-widgets .page-link:hover {
    border-radius: 50%;
    color: $pagination-button-color;
    border: none;
    outline: none;
    box-shadow: none;
}

.select-label {
    white-space: nowrap;
    margin-bottom: 0px;
}

.next-prev-icon {
    border-radius: unset !important;
}