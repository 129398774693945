.status {
    span {
        min-width: 90px;
        padding: 8px 15px;
        text-transform: capitalize;
        display: inline-block;
        text-align: center;
        font-weight: 400;
        font-size: 13px;
    }
    .pending {
        color: var(--atm-color-orange-40) !important;
        background-color: var(--atm-color-orange-5) ;
    }
    .approved {
        color: var(--atm-color-green-40) !important;
        background-color: var(--atm-color-green-5) ;
    }
    .denied { 
        color: var(--atm-color-red-40) !important;
        background-color: var(--atm-color-red-5) ;
    }
    .invalid {
        color: var(--atm-color-grayscale-40) !important;
        background-color: var(--atm-color-grayscale-5) ;
    }
}
hbr-tag::part(base) {
    display: flex;
    justify-content: center;
    min-width: 85px !important;
}