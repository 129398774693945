.metrics-filter-container {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid var(--atm-color-grayscale-10);
  padding: .5rem 0;
  .api-selection {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--atm-color-grayscale-10);
    border-radius: 5px;
    padding: 5px 10px;
    min-width: 350px;
    position: relative;
    .select-field {
      display: flex;
      align-items: center;
      min-height: 48px;
      .select-trigger {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        label {
          margin-bottom: 0!important;
          color: var(--atm-color-grayscale-70);
          &:first-child {
            color: var(--atm-color-grayscale-50)!important;
          }
          span {
            max-width: 500px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0 8px 0 5px;
            margin-left: 5px;
          }
        }
      }
      i {
        display: flex;
        align-self: center;
      }
    }
    .select-container {
      margin: 10px 0;
    }
    hbr-select {
      margin: 10px 0;
    }
    .dropdown-container {
      background: #fff;
      width: 100%;
      padding: 0px 10px 10px 10px;
      border-left: 1px solid var(--atm-color-grayscale-10);
      border-bottom: 1px solid var(--atm-color-grayscale-10);
      border-right: 1px solid var(--atm-color-grayscale-10);
      position: absolute;
      z-index: 9999;
      left: 0;

      .button-container {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        // button{
        //   outline: none;
        //   border: none;
        //   color: var(--atm-color-grayscale-60);
        //   background: #fff;
        //   border: 1px solid var(--atm-color-grayscale-30);
        //   border-radius: 8px;
        //   padding: 5px 16px;
          
        //   &:nth-child(2) {
        //     background: var(--atm-color-blue-50);
        //     color: #fff;
        //     border: none;
        //     margin-left: 8px;
        //   }
        // }
      }
    }
  }
  .date-selection {
    display: flex;
    align-items: center;
    position: relative;
    .date-range-container {
      display: flex;
      .calendar-icon {
        margin-right: 2px;
        i {
          color: var(--atm-color-grayscale-40);
          font-size: 20px;
          padding: 5px;
          cursor: pointer;
        }
      }
      .date-range {
        display: flex;
        color: var(--atm-color-grayscale-70);
        font-size: 14px;
        align-items: center;
        margin-right: 8px;
        p {
          margin-bottom: 0!important;
          &:first-child {
            margin-right: 8px;
          }
        }
      }
    }
    .date-range-picker {
      display: flex;
      background: #fff;
      position: absolute;
      z-index: 9999;
      top: 50px;
      flex-direction: column;
      border: 2px solid var(--atm-color-grayscale-20);
      color: var(--atm-color-grayscale-60);
      padding: 1rem;
      .button-container {
        // button{
        //   width: 100%;
        //   outline: none;
        //   border: none;
        //   color: var(--atm-color-grayscale-60);
        //   background: #fff;
        //   border: 1px solid var(--atm-color-grayscale-30);
        //   border-radius: 8px;
        //   padding: 10px 5px;
          
        //   &:nth-child(2) {
        //     background: var(--atm-color-blue-50);
        //     color: #fff;
        //     border: none;
        //     margin-left: 8px;
        //   }
        // }
      }
      
      @media (max-width: 576px) {
        .rdrDefinedRangesWrapper {
          display: none !important;
        }
        .button-container {
          // button{
          //   &:first-child {
          //     margin-bottom: 8px;
          //   }
          //   &:nth-child(2) {
          //     margin-left: 0;
          //   }
          // }
        }
      }      
    }

    .reload {
      display: flex;
      padding: 0 8px;
      border-left: 1px solid var(--atm-color-grayscale-20);
      border-right: 1px solid var(--atm-color-grayscale-20);
      cursor: pointer;
      hbr-icon {
        font-size: 18px;
        color: var(--atm-color-grayscale-40);
      }
    }
    .reset {
      padding: 0 8px;
      cursor: pointer;
      i {
        font-size: 20px;
        color: var(--atm-color-grayscale-40);
      }
    }
  }
}