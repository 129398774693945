@import "../../../../sass/variables";

.popup-content {
    width: unset !important;
}
.select-box-width {
    width: 130px;
}
.request-table {

    tbody {
        display: block !important;
        min-height: 550px !important;
        max-height: 575px !important;
        overflow-y: scroll !important;
      }
      
     thead, tbody tr {
        display: table !important;
        width: 100% !important;
        table-layout: fixed !important;
      }

    .exit-outline,.check-outline {
        font-size: 20px;
    }

    .delete {
        font-size: 16px;
    }
    
    th {
        font-weight: 400 !important;
    }
    tr td, tr th {
        width: 10% !important;
    }
    tr td:last-child,  tr th:last-child,tr td:nth-child(6),  tr th:nth-child(6){
       width: 8% !important;
        i {
            color: var(--atm-color-blue-50);
        }
    }

    tr td:first-child,  tr th:first-child{
        width: 3% !important;
        i {
            color: var(--atm-color-blue-50);
        }
    }
   
    td {
        
        vertical-align: middle !important;
        font-weight: 200 !important;
        font-size: 14px;
        .btn-outline-dark{
            background-color: unset !important;
            border-color: var(--atm-color-grayscale-60) !important;
            color: var(--atm-color-grayscale-60) !important;
        }
        .action {
            button {
               // min-width: 100px !important;
                margin-right: 10px !important;
            }
        }
        .empty-state {
            height: 300px;
            align-content: center;
        }
    }
    .myaccess-search-container {
        .search-inp {
            width: 500px;
            border: 1px solid var(--atm-color-grayscale-5);
            border-radius: 500px;
            padding: 6px 12px 6px 25px;
        }
        .search-btn {
            margin-left: -45px !important;
        }
    }
}

.modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    background-color: #000000ad;
    z-index: 1;
    .modal-body {
        width: 500px !important;
        padding: 30px 40px !important;
        .resize-none {
            resize: none !important;
            width: 100%;
        }
        .min-w-reason{
            min-width: 158px !important;
        }
    
    }
}

.no-caret::after {
    display: none !important;
}

  .dropdown-item {
    padding: 10px 15px !important;
    font-size: 15px;
  }
