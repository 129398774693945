.portalGroupCard {
    min-height: 80vh;

    .title-td {
        width: 20%;
    }

    .no-td {
        width: 5%;
    }

    .header-title {
        font-size: 18px;
        font-weight: 500;
    }

    .card {
        border-radius: 15px;
        min-width: 300px;
        max-width: calc(25% - 32px);
        padding: 0px;
        cursor: pointer;

        .card-img-top {
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
        }

        &:hover {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }

        .card-img-ico {
            top: 34px;
            position: absolute;
            height: 70px;
            width: 78px;
            left: 36%;
        }

        .card-body {
            .title {
                color: var(--atm-color-blue-60);
                font-size: 18px;
                font-weight: 500;
            }

            .description {
                text-align: left;
            }
        }

        .card-footer {
            padding-top: unset;
            border: unset;
            background-color: unset;
        }
    }

    .search {
        font-size: 21px !important;
    }

    .h5 {
        font-weight: 500;
    }

    .header {
        border-bottom: 1px solid var(--atm-color-grayscale-20);
    }

    .view-type {
        .title {
            min-width: 40px;
        }

        .view-card-icon {
            font-size: 24px;
        }

        .view-list-icon {
            font-size: 20px;
        }

        .type-active {
            color: var(--atm-color-blue-60);
            background-color: var(--atm-color-blue-5);
            border-radius: 10px;
        }
    }

}