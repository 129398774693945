.getting-started-container {
    display: flex;
    flex-direction: column;
    font-family: "CiscoSans", "Arial", sans-serif;
    .features-header {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 55px 15px!important;
        border: 0!important;
        background-color: var(--atm-color-brand-secondary);
        align-items: center;
        color: var(--atm-color-grayscale-0);
        margin-bottom: 30px!important;
        .header-content-container {
            max-width: 1140px;
            .header-title {
                font-size: 2.625rem;
                font-weight: 300;
                line-height: 1;
            }
            .header-desc {
                font-size: 1.125rem;
                font-weight: 200;
                margin-top: 15px;
            }
        }
        margin-bottom: 50px;
    }
    .pubhub-feature-section-body {
        display: flex;
        width: 100%;
        justify-content: center;
        .card-row {
            max-width: 1320px;
            width: 100%;
            .pubhub-feature-section-card-container {
                display: flex;
                padding: 15px;
                text-decoration: none;
                
                .pubhub-feature-section-card {
                    border-radius: 15px;
                    border: 1px solid var(--atm-color-grayscale-10);
                    overflow: hidden;
                    margin-bottom: 16px;
                    flex: 1 1 auto;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    .card-header {
                        padding: 20px 30px;
                        background-color: var(--atm-color-brand-secondary) !important;
                        color: var(--atm-color-grayscale-0);
                        text-align: left;
                        .card-title {
                            font-size: 18px;
                            font-weight: normal;
                        }
                    }
                    .card-body {
                        padding: 8px 30px 30px;
                        text-align: left;
                        background-color: var(--atm-color-grayscale-0);
                        .card-description {
                            color: var(--atm-color-grayscale-70);
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}