.features-header {
    text-align: center;
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 55px 0!important;
        border: 0!important;
        background-color: var(--atm-color-brand-secondary);
        align-items: center;
        color: var(--atm-color-grayscale-0);
    
    .header-title {
        font-size: 2.625rem;
                font-weight: 300;
                line-height: 1;
    }

    .header-description {
        font-size: 1.125rem;
                font-weight: 200;
                margin-top: 15px;
    }

    margin-bottom: 50px;
  }

li {
    margin: 10px 0;
}

.section {
    margin-bottom: 70px;
    h5 {
        padding-bottom: 5px;
        font-weight: bold !important;
    }

    ul {
        padding-left: 20px;

    }
}
