
h1, h2, h3, h4, h5, h6, p, a{
  font-family: "CiscoSans", "Arial", sans-serif;
}

h1 {
  color: $blue;
  font-weight: $font-weight-extra-light;
  margin: 20px 0 20px 0;
}

h2 {
  font-weight: $font-weight-extra-light;
  margin: 20px 0 20px 0;
}

h3 {
  margin: 30px 0 10px 0;
  font-weight: $font-weight-light;

}

h4 {
  margin: 0.5rem 0;
  font-weight: $font-weight-light;
}

h5 {
  margin: 0.5rem 0;
  font-weight: ceil($font-weight-light * 1.333);
}

h6 {
  margin: 0.5rem 0;
  font-weight: ceil($font-weight-light * 1.333);
  line-height: 2em;
}
