.metrics-container {
    min-height: 500px;
    .tab-container {
        position: relative;
        .tab-sections {
            background-color: var(--atm-color-grayscale-5);
            min-height: 50vh;
        }
        .download-pdf {
            position: absolute;
            right: 5px;
            top: 5px;
        }
    }
}