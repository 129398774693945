.notification-dropdown {
    width: 320px;
    margin-top:0;   
  }
  .dropdown-header {
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 16px;
    background-color: var(--atm-color-grayscale-5); 
    color: var(--atm-color-grayscale-90);
    border-radius: 12px 12px 0 0;
  }
  
  .notification-count {
    margin-left: 8px;
    background-color: var(--atm-color-blue-70);
    color: white;
    border-radius: 20px;
    padding: 0 12px;
    font-size: 12px;
  }
  .items-container {
    max-height: 360px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 12px;
  }
  &::-webkit-scrollbar-track {
      background: var(--atm-color-grayscale-10);
  }
  &::-webkit-scrollbar-thumb {
      background: var(--atm-color-grayscale-30);
  } 
  &::-webkit-scrollbar-thumb:hover {
      background: var(--atm-color-grayscale-50);
  }
  }
  .dropdown-menu-custom {
    border-radius: 12px;
    border: 1px solid var(--atm-color-grayscale-20);
    width: 360px;
    padding-top: 0;
    .atm-icon {
        &.bell-filled {
            color: var(--atm-color-orange-40);
            font-size: 16px;
            cursor: pointer;
        }
      }
  }
  
  .notification-item {
    padding: 12px 16px;
    border-bottom: 1px solid var(--atm-color-grayscale-5);
    word-wrap: break-word;
    white-space: normal;

  }
  
  .notification-title {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 4px;
    word-wrap: break-word;
    span {
        margin-left: 4px;
        vertical-align: middle;
    }
  }
  
  .notification-date {
    color: #999;
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .notification-description {
    color: #666;
    font-size: 14px;
  }
  .empty-notifications {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
    padding: 40px;
  }