#pubhub-nav-vp.pubhub-nav-vp-container{
}

#pubhub-nav-vp .pubhub-nav-vp{

  // react-select
  &__control{
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 0px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
  }

  &__menu{
    background-color: hsl(0,0%,100%);
    margin-bottom: 8px;
  }
}
