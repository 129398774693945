.red-0 {
    color: red !important;
}

.request-access-form {
    .form-group {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
  
      .form-label {
        font-weight: 400;
        min-width: 200px;
      }
    }
  
    select {
      height: 50px;
      &.class-empty {
        color: #b4b4b4;
      }
    }
  }

.denial_label {
    color: var(--atm-color-grayscale-70);
    margin-bottom: 5px;
}
.help-text {
    text-align: right !important;    
}