.expanded-row {
    justify-content: space-evenly;
    .expand-row-section {
        display: flex;
        align-items: center;
        .api-detail {
            line-height: 26px;
            .api-detail-version {
                padding: 2px 4px;
                background-color: var(--atm-color-blue-60);
                color: #fff;
            }
        }
        
        .vertical-line {
            width: 110px !important;
            transform: rotate(90deg) !important;
            border-top: 1px solid black !important;
        }
    }
}