.portal-banner {
  .banner {
    width: 100%;
    min-height: 300px;
    background: linear-gradient(94deg, #233A54 31.17%, #203E57 41.2%, #204C5B 55.02%, #11CB87 98.26%);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .wrap-square {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
  }
  .spinning-squares {
      position: absolute;
      right: 55%;
      top: 47%;
      transform: translateY(-50%);
      .square {
        animation: spin 18s linear infinite;
      }
  }

  .spinning-squares-2 {
    position: absolute;
    right: 44%;
    bottom: 142%;
    .square {
      animation: sqtMove 7s ease-in-out infinite;
      transform: rotate(45deg)
    }
  }  

  .square {
      width: 400px;
      height: 400px;
      position: absolute;
      top: 0;
      opacity: 0.4;
      border: 15px solid #32a78a91;
     

  }

  .square-1 {
      
      animation-direction: normal;
      left: 0;
  }

  .square-2 {
      animation-direction: reverse;
      transform: rotate(45deg);
      left: 200px;
  }

  @keyframes spin {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
  }

  @keyframes sqtMove {
    0% {
      opacity: 0.1;
      transform: rotate(45deg) translateX(0px);
      
    }
    10% {
      opacity: 0.3;
    }

    15% {
      opacity: 0.4;
    }

    90% {
      opacity: 0.4;
    }

    91% {
      opacity: 0.2;
    }

    100% {
      opacity: 0.1;
      transform:  translateX(-200px) rotate(45deg);
    }
  }
    background-repeat: no-repeat;
    background-size: cover;
  //  min-height: 360px;
    .video_css {

       width: 100vw;
       height: 300px;
       object-fit: cover;
      
      }
      .video-parent {
        position: relative;
      }
    position: relative;
      .banner-content {
        .header {
            color: white;
            margin-bottom: 32px !important;
        }
        .description-ph {
            color: white;
           // width: 50%;
        }
        .whats-new {
            background-color: white;
            border-radius: 20px;
            .whatsNew-header {
                font-weight: 500 !important;
                margin-top: 0px !important;
                color: var(--atm-color-blue-60);
            }
            .sub-text {
                font-weight: 200 !important;
            }
        }
      }
      .global-search-container {
          width: 100%;
          max-width: 100%;
          height: 55px;
          .search-container {
           width: 100%;
           padding: 0px 30px;
          }
          .result-container {
              width: 100%;
              max-width: 100%;
          }
      }
    
}

