.border-header {
    border-bottom: 1px solid #e0e0e0;
    padding: 8px 0px;
    .notification-icon,.search {
        font-size: 24px !important;
    }
    .header-logo {
        text-decoration: none !important;

        .header-logo-text {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 0;
            color: var(--atm-color-blue-80);
            letter-spacing: 1px;
            line-height: 1.1;
        }
    }
}

.fixed-act-as-partner {
    position: relative;
    height: 52px;
    &.act-position-fixed {
        width: 100%;
        position: fixed;
        z-index: 999;
        top: 0;
    }
}

.login-text {
    cursor: pointer;
}


.pending-request {
    position: relative;
    .notification-dot {
        top: 5px;
        right: 0px;
        position: absolute;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: red;
    }
}

.user-logo {
    border-radius: 50%;
    background-color: #0D274D;
    color: white;
    padding: 8px 5px;
    font-size: 13px;
    font-weight: 500;
    height: 35px;
    width: 35px;
    text-align: center;
    cursor: pointer;
}
.no-caret::after {
    display: none;
  }
.header-link {
    font-size: 14px;
    color: var(--atm-color-background-accent-pressed);
    text-decoration: none !important;
    padding-right: 15px;
}

.dropdown-menu {
    border-radius: 12px;
}
.drop-down-container {
    .profile-container {
        align-items: baseline;
        min-width: 350px;

        .user-name {
            margin-bottom: 0px;
            font-size: 20px;
            color: var(--atm-link-text-default);
            white-space: nowrap;
        }

        .user-id {
            font-weight: 300;
            font-size: 14px;
        }
    }
}
.dropDown-optn {
    .sub-txt {
        font-size: 15px;
        color: var(--atm-color-grayscale-40);
        font-weight: 200; 
    }
    .logout-icon {
        font-size: 20px;
        
    }
}
