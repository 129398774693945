@keyframes pba-sn-icon{
    from{
        transform: translateY(10px);
    }
    to{
        transform: translateY(0);
    }
}

@keyframes pba-sn-title{
    from{
        opacity: 0;
        transform: translateY(20px);
    }
    20% {
        opacity: 0;
        transform: translateY(20px);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pba-sn-desc{
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    30% {
        opacity: 0;
        transform: translateY(30px);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

.pubhub-site-notification{
    padding: 2em 0;
    color: #333;

    &.pubhub-site-notification-success{
        color: #3c763d;
        background-color: #dff0d8;
    }

    &.pubhub-site-notification-info{
        background-color: #d9edf7;
    }

    &.pubhub-site-notification-warning{
        background-color: #FCE166;
    }

    &.pubhub-site-notification-danger{
        color: #fff;
        background-color: #f33;
    }
    
    &-content{
        display: flex;
    }

    &-text-container{
        flex: 1 1 auto;
    }

    &-icon-container{
        margin-right: 2em;
    }

    &-icon{
        width: 42px;
        height: 42px;
        animation: pba-sn-icon 0.6s;
    
        svg{
            width: 100%;
            height: 100%;
        }
    
        path{
            fill: currentColor;
        }
    }

    &-title{
        font-size: 18px;
        animation: pba-sn-title 0.6s;
    }

    &-description{
        margin-top: 12px;
        font-size: 14px;
        animation: pba-sn-desc 1s;
    }
}