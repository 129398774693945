@import "common/common";
@import "font";
//doc
#pubhub-container, .pubhub-doc-container {
  @import "doc/base";
  @import "doc/common";
  @import "doc/variables";
  @import "doc/search";
  @import "doc/nav";
  @import "doc/tab";
  @import "doc/content";
  @import "doc/switcher";
  @import "doc/content_xmart";
  @import "doc/content_swagger";
  @import "doc/code-panel";
}

.navigate {
  top: 0;
}
dwc-header ~ div .navigate {
  top: 57px;
  @media (min-width: 992px) {
    top: 0;
  }
}

.section-padding {
  padding: 64px 80px;
}

.section-padding-sm {
  padding: 56px 80px;
}

.h-doc-section {
  // border-bottom-right-radius: 24px !important;
  // border-top-right-radius: 24px !important;
  // font-size: 28px !important;
  // padding: 8px !important;
  // padding-right: 43px !important;
  // width: max-content !important;
  // background-color: #049fd9 !important;
  // color: white !important;
  // font-weight: 300 !important;
  // margin-left: -21px;
  font-size: 35px !important;
  
}

.h-doc-sub-section {
// margin-left: -21px;
//   border-bottom-right-radius: 24px !important;
//   border-top-right-radius: 24px !important;
font-size: 24px !important;
// padding: 8px !important;
// padding-right: 43px !important;
// width: max-content !important;
// background-color: var(--atm-color-orange-30) !important;
// color: black !important;
font-weight: 400 !important;
&.overview {
 // background-color: var(--atm-color-green-30) !important;
  .product-design-define {
    font-size: 33px !important;
  //  margin-right: 5px;
    font-weight: 500 !important;
    
  }
}
}
.screen_shorts {
  box-shadow: var(--atm-color-blue-40) 0px 2px 8px 0px;
  border: 1px solid var(--atm-color-grayscale-5);
  border-radius: 15px;
}

.iap-btn {
  &.btn-dflt {
    padding: 5px 15px !important;
    border-radius: 30px !important;
    cursor: pointer !important;
    font-size: 15px !important;
    &:disabled {
      opacity: 0.6 !important;
      cursor: not-allowed !important;
    }
  }
  &.btn-primary {
    @extend .btn-dflt;
    background-color: var(--atm-color-blue-60) !important;
    border-color:  var(--atm-color-blue-60)!important;
    color: #fff !important;
    &:hover {
      background-color: var(--atm-color-blue-70) !important;
    }
  }
  &.btn-secondary {
    @extend .btn-dflt;
    background-color: #fff !important;
    color:  var(--atm-color-blue-60)  !important;
    border-color:  var(--atm-color-blue-60)  !important;
    &:hover {
      background-color: var(--atm-color-blue-70)  !important;
      border-color:  var(--atm-color-blue-70) !important;
      color: #fff !important;
    }
  }
  
  &.btn-danger {
    @extend .btn-dflt;
  }

  &.btn-outline-danger {
    @extend .btn-dflt;
    background-color: transparent;
    color: var(--atm-color-red-40);
    color:  var(--atm-color-red-40) !important;
    border-color:  var(--atm-red-blue-40) !important;
    &:hover {
      background-color: var(--atm-color-red-50) !important;
      border-color:  var(--atm-color-red-50)!important;
      color: #fff !important;
    }
  }
}
.pubhub-page-title {
  @import "doc/variables";
  font-size: 32px !important;
  font-weight: 400!important;
  color: $blue!important;
}

.iap-table {
  th {
    background-color: var(--atm-color-grayscale-5) !important;
    font-size: 14px !important;
    color: var(--atm-color-grayscale-70) !important;
  }
  .expand_row-td {
      padding: 5px 5px !important;
      .expanded-row {
        padding: 15px 15px !important;
        background-color:  var(--atm-color-grayscale-5) !important ;
      }
  }
}

.app-modal-popup {
  @import "doc/variables";
  &.fixed-width {
    .modal-dialog {
      width: 480px;
    }
  }
  .modal-header {
    color: var(--atm-color-blue-60);
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
    font-size: larger;
    .btn-close {
      font-size: x-small;
     // filter: invert(1) grayscale(100%) brightness(200%);
    }
  }
  .modal-body {
    padding: 10px 20px !important;
    &.modal-centered {
      align-content: center;
      text-align: center;
      min-height: 135px;
  }
  .message {
    color: var(--atm-color-grayscale-50);
    font-weight: 300;
    font-size: 15px;
  }
  }
  .modal-footer {
    .btn-dflt {
      padding: 5px 15px;
      border-radius: 30px;
      cursor: pointer;
      font-size: 15px;
      &:disabled {
        opacity: 0.6 !important;
        cursor: not-allowed !important;
      }
      
    }
    border-top: unset !important;
    padding: 20px 10px;
    .btn-primary {
      @extend .btn-dflt;
      background-color: var(--atm-color-blue-60) !important;
      border-color:  var(--atm-color-blue-60)!important;
      color: $off-white !important;
      &:hover {
        background-color: var(--atm-color-blue-70) !important;
      }
    }
    .btn-secondary {
      @extend .btn-dflt;
      background-color: #fff !important;
      color:  var(--atm-color-blue-60) !important;
      border-color:  var(--atm-color-blue-60) !important;
      &:hover {
        background-color: var(--atm-color-blue-70) !important;
        border-color:  var(--atm-color-blue-70)!important;
        color: $off-white !important;
      }
    }
    .btn-danger {
      @extend .btn-dflt;
    }  
  }   

}

.pubhub-site-container {
  @import "site/index";
}

#pubhub-widgets, .pubhub-widgets {
  @import "bootstrap";
  @import "boostratp_extension/index";
  @import "common/widget";
  @import "widgets";

  .widget-loading {
    opacity: 0;
  }
  .widget-loading img {
    opacity: 0;
  }
}

//vendor
// @import "lib/aos/aos.css";
@import "../../../node_modules/selectize/dist/css/selectize.default.css";
@import "common/plugin";

// 404
#pubhub-widgets .dn-404, .pubhub-widgets .dn-404 {
  background: url(./img/404.png);
  background-size: 943px;
  height: 80%;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 30px;
  min-height: 800px;
  @media screen and (max-width: 943px) {
    background-size:contain;
    min-height: 400px
  }

  a {
    border-radius: 4px;
    background-color: #00ace1;
    padding: 15px 75px;
    font-family: CiscoSans;
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 562px;
    top: 443px;
    @media screen and (max-width: 943px) {
      left: 50%;
      top: 300px;
      margin-left: -132px
    }
  }
}