.features-header {
    border-top: 55px solid var(--atm-color-background-accent-pressed);
    text-align: center;

    h2 {
        font-weight: bold !important; 
    }

    p {
        font-size: 0.9rem;
    }

    margin-bottom: 50px;
  }

li {
    margin: 10px 0;
}