.g-started-screen {
  display: flex;
  height: 100vh;
  &.home {
    background: linear-gradient(100deg, #FFF8E8 65.76%, #FAFFFA 101.15%);
    background-size: cover;
    background-repeat: no-repeat;
   // background-color: #041f37;


    .menu {
      border-radius: 15px;
      margin: 16px;
      background-color: #f9f9f98a;
    }
    .g-started-content {
      background-size: cover;
      background-repeat: no-repeat;
      
     // background-image: url(https://w0.peakpx.com/wallpaper/1004/117/HD-wallpaper-technology-texture-blue-technology-background-creative-blue-background-technology-background-blue-neon-background-blue-neon-abstraction.jpg)
      ;

    }

   
    //url(https://local.cisco.com:3002/pages/site/gettingStarted/asserts/image/bg1.jpg);
    //url(https://media.istockphoto.com/id/1402508063/vector/abstract-concentric-circle-background-layers.jpg?s=170667a&w=0&k=20&c=glup4M3MyVg2JyEvLnlVMPia6p5crdux5BdRQ9IEh1E=);
  }

  .menu {
    height: 90vh;
    width: 100%;
    max-width: 290px;
    box-sizing: border-box;
    border-right: 1px solid #ccc;
    overflow-y: auto;
    // &::-webkit-scrollbar {
    //   width: 7px;
    // }
    // &::-webkit-scrollbar-track {
    //     background: var(--atm-color-grayscale-10);
    // }
    // &::-webkit-scrollbar-thumb {
    //     background: var(--atm-color-grayscale-20);
    // } 
    // &::-webkit-scrollbar-thumb:hover {
    //     background: var(--atm-color-grayscale-30);
    // }
    .expand-collapse-controls {
      color: var(--atm-color-grayscale-60);
      padding: 20px 20px;
      a {
        cursor: pointer;
      }
    }
    .menu-item {
      cursor: pointer;
      padding: 0;
      display: block;
      color: var(--atm-color-grayscale-80);
      font-weight: 400;
      margin-bottom: 3px;

      > label {
        width: 100%;
        padding: 5px 20px;
        margin: 0;
        &:hover {
          color: var(--atm-color-content-accent-default);
            background-color: var(--atm-color-blue-5);
        }
        i {
          font-size: 12px;
          margin-right: 5px;
          padding-bottom: 2px;
          font-weight: bold;
        }
        cursor: pointer;
      }
      .submenu {
        display: none;
        list-style: none;
        margin: 0;
        padding: 0;
        color: var(--atm-color-grayscale-70);
        font-weight: 400;

        li {
          cursor: pointer;
          display: block;
          padding: 5px 5px;
          margin: 0;
          label {
            cursor: pointer;
            display: block;
            padding-left: 30px;
            margin: 0;
          }
          &.selected {
            border-right: 5px solid;
            color: var(--atm-color-content-accent-default);
            background-color: var(--atm-color-blue-10);
        }
          &:hover {
            color: var(--atm-color-content-accent-default);
            background-color: var(--atm-color-blue-5);
          }
        }
      }
      
      .submenu.show {
        display: block;
      }
    }
  }

  .g-started-content {
    padding: 30px 20px 20px;
    flex-grow: 1;
    overflow-y: auto;
   

    .h-doc-section {
      // border-bottom-right-radius: 24px !important;
      // border-top-right-radius: 24px !important;
      // font-size: 28px !important;
      // padding: 8px !important;
      // padding-right: 43px !important;
      // width: max-content !important;
      // background-color: #049fd9 !important;
      // color: white !important;
      // font-weight: 300 !important;
      // margin-left: -21px;
      font-size: 35px !important;
      
    }

 .h-doc-sub-section {
  // margin-left: -21px;
  //   border-bottom-right-radius: 24px !important;
  //   border-top-right-radius: 24px !important;
    font-size: 24px !important;
    // padding: 8px !important;
    // padding-right: 43px !important;
    // width: max-content !important;
    // background-color: var(--atm-color-orange-30) !important;
    // color: black !important;
    font-weight: 400 !important;
    &.overview {
     // background-color: var(--atm-color-green-30) !important;
      .product-design-define {
        font-size: 33px !important;
      //  margin-right: 5px;
        font-weight: 500 !important;
        
      }
    }
 }
    .screen_shorts {
      box-shadow: var(--atm-color-blue-40) 0px 2px 8px 0px;
      border: 1px solid var(--atm-color-grayscale-5);
      border-radius: 15px;
    }

    .content-section {
      .header-content {
        .header {
          font-size: 30px;
          font-weight: 500 !important;
        }
        
        .sub-head {
          font-size: 24px;
        }
      }
    
      h3 {
        color: var(--atm-color-grayscale-70);
        font-weight: 500;
        margin: 0 0 20px 0;
      }
    }
  }
}

.header-content {
  .header {
    font-size: 52px;
    margin-top: 64px;
    font-weight: 400 !important;
    color: var(--atm-color-brand-skyblue);
    position: relative;
    .pico {
      position: absolute;
      //left: 5%;
      opacity: 1;
      mix-blend-mode: exclusion;
      color: var(--atm-color-orange-30);
      animation: picotMove 7s ease-out infinite;
    }
    @keyframes picotMove {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-200px);
      }
    }
  }
  
  .sub-head {
    font-size: 20px;
    color: var(--atm-color-bluegray-60);
  }
}






