.getting-started-section {
    .getting-started-container {
        min-height: 360px;
        width: 100%;
        background-color: var(--atm-color-brand-midnightblue);
        color: #FFFF; 
        .header_text {
            color: var(--atm-link-text-default);
            font-size: var(--atm-font-size-200) !important;
            font-weight: 500 !important;
            margin-bottom: 20px !important;
        }
        .header_sub_text {
            color: var(--atm-link-text-default);
            font-size: var(--atm-font-size-150) !important;
            font-weight: 300 !important;
            margin-bottom: 20px !important;
        }
        .sub_text {
            color: var(--atm-color-grayscale-70);
            font-size: var(--atm-font-size-100) !important;
            font-weight: 400 !important;
            margin-bottom: 25px !important;
        }
        .getting-started-image {
            width: 100%;
            max-width: 380px;
            text-align: center;
        }
        .section-up {
            width: 100%;
            // max-width: 700px;
            background: rgba(255, 255, 255, .73);
            padding: 30px;
        }
    }
}