//
// Be careful when you add content to this file
//
html, body {
  padding: 0;
  margin: 0;
  font-size: 16px;

}

.pubhub-loading {
  background: url("../img/balls.svg") center center no-repeat;
  display: inline-block;
  width: 6.5rem;
  height: 6.5rem;
}

.pubhub-loading-s {
  background: url("../img/balls.svg") center center no-repeat;
  display: inline-block;
  width: 3.5rem;
  height: 3.5rem;
  background-size: cover;
}

.cursor-pointer{
  cursor: pointer;
}
pre[class*="language-"]{
  border-radius: 0 !important;
  box-shadow:none;
  color: #e1e1e1 !important;
  border:none 0 !important;
}

code[class*="language-"]{
  white-space: inherit !important;
  text-shadow: none !important;
}

.restrict_body{
  height: 100%;
  overflow: hidden;
}


.secureText {
  -webkit-text-security: disc;
}

.scrollable-span {
  display: inline-block; /* Or 'block' if it fits your layout */
  overflow-x: auto;     /* Enable horizontal scrolling */
  max-width: 100%;      /* Set a max-width to trigger the scrollbar when needed */
  word-break: break-all;
}

.green {
  color: green;
}

.pr-5 {
  padding-right: 5px;
}