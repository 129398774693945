.overview-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  .chart {
    flex: 0 1 100%;
    padding: 1.5rem 1.5rem 4rem 1.5rem;
    background: #fff;
    margin: 1rem 5px;
    border: 1px solid var(--atm-color-grayscale-10);
  }

  .chart-3, .chart-4 {
    flex: 0 0 49%;
  }

  @media screen and (max-width: '1400px') {
    .chart-3, .chart-4 {
      flex: 0 0 100%!important;
    }
  }

  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #555;
    z-index: 10;
  }
}