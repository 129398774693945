.whats-new-container {
    width: 1440px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    .feature-link {
        font-size: 16px;
    }
}
