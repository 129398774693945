.community-section {
  background: linear-gradient(180deg, #F1EEFD 45.03%, #FFFFFF 74.01%);
  .cards-container {
    display: flex;
    justify-content: left;
    //flex-wrap: wrap;
    gap: 20px;

    .card {
      background-color: #ffffff;
      border: 1px solid #DEE2E6;
      border-radius: 15px;
      padding: 20px;
      max-width: 300px;
      text-align: left;
      display: flex;
      flex-direction: column;

      h4 {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 500;
      }

      p {
        font-size: 16px;
        color: #666;
        margin-bottom: 20px;
        flex: 2;
      }

      a {
        text-decoration: none;
        font-weight: 500;
        font-size: 12px;
        display: inline-flex;
        align-items: start;

        i {
          margin-left: 5px;
          font-size: 12px;
        }
      }
    }
  }

  img {
    border-radius: 15px;
  }

  h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
  }

  .illustration-img {
    max-width: 415px;
  }

  @media (max-width: 992px) {
    .cards-container {
      justify-content: center; 
      gap: 10px; 
    }

    .card {
      max-width: 100%; 
    }
  }

  @media (max-width: 576px) {
    padding: 10px; 

    h2 {
      font-size: 24px;
    }

    .card {
      h4 {
        font-size: 16px;
      }

      p {
        font-size: 12px;
      }
    }
  }
}
